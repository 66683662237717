
@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* a.active {
  color:#fff;
  font-weight: 500;
   background: #00443c;
} */
a.active::before {
  content: "";
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  background-color: #00443c;
}

/* a:hover{
  background-color: #00443c;
  color:#fff;
} */

nav{
  transition:all .2s;
}
.expanded .link-label {
  display: none;
}
.sidebar-area{
  width: 20%;
}
.content-area{
  width: 80%;
  float: right;
}
.content-area .content{
  padding: 20px;
}
.sidebar-area.expanded{
  width: 5%;
  z-index: 999;
}

.expanded+.content-area{
  width: 95%;
  float: right;
}
.expanded ul li{
  align-items: center;
  justify-content: center;
}

.expanded ul li a{
  align-items: center;
  justify-content: center;
}
.expanded ul li a svg{
 margin: 0;
}

.account-setup {
  flex: 0 0 100%;
}

.loginRegister, .productButton {
  margin-top: 1rem;
}

.productLogout, .termsAgreementLogout {
  margin-left: 2rem;

}

/* .register, .login a Link {
  text-decoration: none;
  color: #00433c;
} */

.MuiGrid-root.products, .MuiGrid-root.termsAgreement {
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 0 0 3.5rem;
}

.productContainer, .termsAgreementContainer {
  display: flex;
  align-items: center;
  margin-top: 1.3rem;
}



.MuiGrid-root.products .productForm {
  margin-left: 6rem;
  margin-right: 6rem;
}

.MuiGrid-root .termsAgreementForm {
  margin-left: 3rem;
  margin-right: 9rem;
}

@media screen and (max-width: 1024px) {
  .MuiGrid-root .termsAgreementForm {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}


.MuiStepIcon-root.Mui-active, .MuiStepIcon-root.Mui-completed {
  color: #00433c !important;
}

.MuiStepIcon-root.Mui-error {
  color: #D32f2f !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #00433c !important;
}

.customMargins {
  margin-top: 16px;
  margin-bottom: 8px;
}

.MuiOutlinedInput-input {
  border-bottom: solid 1px #00433c !important;
}

.MuiFormHelperText-root {
  color: red !important;
}

.MuiButton-containedPrimary {
  background: #00433c !important;
}

.MuiButton-containedPrimary:hover {
  background: #00433c !important;
}

.products > h2 {
  flex-grow: 1;
}
.custom-wrapper-width .min-w-\[200px\]{
  min-width: fit-content;
}

.screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff59;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 999999;
}

.loader {
  width: 100%;
  height: 15px;
  text-align: center;
}

.dot {
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0 2px;
  display: inline-block;
}

.dot:first-child:before {
  animation-delay: 0ms;
}

.dot:first-child:after {
  animation-delay: 0ms;
}

.dot:last-child:before {
  animation-delay: 200ms;
}

.dot:last-child:after {
  animation-delay: 200ms;
}

.dot:before {
  content: "";
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: blue;
  animation-name: dotHover;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(0.82, 0, 0.26, 1);
  animation-iteration-count: infinite;
  animation-delay: 100ms;
  background: #00433c;
  border-radius: 100%;
}

.dot:after {
  content: "";
  position: absolute;
  z-index: -1;
  background: black;
  box-shadow: 0px 0px 1px black;
  opacity: 0.2;
  width: 100%;
  height: 3px;
  left: 0;
  bottom: -2px;
  border-radius: 100%;
  animation-name: dotShadow;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(0.82, 0, 0.26, 1);
  animation-iteration-count: infinite;
  animation-delay: 100ms;
}

@keyframes dotShadow {
  0% {
    transform: scaleX(1);
  }
  50% {
    opacity: 0;
    transform: scaleX(0.6);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes dotHover {
  0% {
    top: 0px;
  }
  50% {
    top: -50px;
    transform: scale(1.1);
  }
  100% {
    top: 0;
  }
}
::-webkit-file-upload-button {
  display: none;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d38; 
}
.sidebar li a.active svg g,.sidebar li a:hover svg g {
  fill: #fff;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon{
  background-color: #00433c;
  border-color: #00433c;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon{
top: -3px !important;
}
.ant-btn-primary{
  background-color: #00433c;
  border-color: #00433c;
}
.ant-btn-primary:hover{
  background-color: #000 !important;
  border-color: #000;
}
.MuiDialog-root.MuiModal-root{
z-index: 1 !important;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item{
  margin-inline-start:0px !important;
}
.service-items .ant-space-vertical{
width: 100%;
gap: 0 !important;
}
.ant-space-item {
  padding: 5px 0px;
}
.service-items .ant-space-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.service-items .ant-space-item:hover {
  background: #ccc;
}
.custom-tabs-radio .ant-radio-button-wrapper-checked{
  background: #00433c !important;
  border-color:#00433c !important;
}
.service-tab-opt {
display: flex;
width: 100%;
justify-content: space-between;
}
.service-duration {
  font-size: 12px;
  color: #6e6e6e;
}
.ant-radio-wrapper .ant-radio-inner{
  width: 20px !important;
  height: 20px !important;
  border-width: 3px !important;
}
.ant-radio-checked .ant-radio-inner{    
  border-color: #00443c !important;
  background-color: #ffffff !important;
}
.ant-radio-wrapper .ant-radio-inner::after{
  width: 20px;
    height: 20px;
    margin-block-start: -10px;
    margin-inline-start: -10px;
    background-color: #00443c;
}
.ant-popconfirm-buttons{
  text-align: center !important;
}